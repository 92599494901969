import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { flatMenuToHierarchical } from "../../lib/functions";

import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "./ContentWrapper";
import SideWrapper from "./SideWrapper";
import Content from "../components/Content";
import NumberedNav from "./NumberedNav";

const FrontContent1 = ({ ...rest }) => {
  const { wp, wpMenu } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFrontContent1 {
          frontContent1 {
            content: fc1Content
          }
        }
      }
      wpMenu(name: { eq: "Areas of Practice - Front" }) {
        id
        count
        menuItems {
          nodes {
            label
            path
            id
            parentId
          }
        }
      }
    }
  `);
  const { content } = wp.acfOptionsFrontContent1.frontContent1;
  const { menuItems } = wpMenu;

  return (
    <PageWrapper front {...rest}>
      <ContentWrapper front>
        <Content article>{content}</Content>
      </ContentWrapper>
      <SideWrapper front>
        <NumberedNav menu={flatMenuToHierarchical(menuItems.nodes)} />
      </SideWrapper>
    </PageWrapper>
  );
};

export default FrontContent1;
