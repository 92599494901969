import React from "react";
import tw from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";

import Content from "./Content";
import PageWrapper from "./PageWrapper";
import ThumbnailVideo from "./ThumbnailVideo";

const Left = tw.div`relative w-full xl:w-1/2 `;
const Right = tw.div`relative w-full -mt-20 pb-20 xl:(w-1/2 pt-20 mt-0)`;

const FrontContent2 = ({ ...rest }) => {
  const { content, video } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFrontContent2 {
          frontContent2 {
            content: fc2Content
            video: fc2Vide {
              embed
              thumbnail {
                ...GatsbyImage_Blur
              }
            }
          }
        }
      }
    }
  `).wp.acfOptionsFrontContent2.frontContent2;

  return (
    <PageWrapper
      tw="bg-offWhite px-6 md:px-12 gap-0 xl:gap-36 mt-40 overflow-visible"
      {...rest}
    >
      <Left>
        <ThumbnailVideo fc2 {...video} tw="" />
      </Left>
      <Right>
        <Content article>{content}</Content>
      </Right>
    </PageWrapper>
  );
};

export default FrontContent2;
