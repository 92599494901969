import React from "react";
import tw from "twin.macro";

const Div = tw.div`flex flex-col items-center justify-start opacity-20 relative gap-4`;
const Dot = tw.div`rounded-full bg-white w-1 h-1`;
const Vert = tw.div`bg-white w-px h-[10rem] max-h-[4vw]`;
const Street = tw.div`absolute bg-white top-0 w-[14rem] h-px mt-8`;

const HeroAccent = ({ ...rest }) => {
  return (
    <Div {...rest}>
      <Dot />
      <Vert />
      <Street />
    </Div>
  );
};

export default HeroAccent;
