import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";

import PageWrapper from "../components/PageWrapper";
import Content from "../components/Content";
import Button from "./Button";
import Parallax from "./Parallax";

const Top = tw.div`grid grid-cols-1 w-full relative sm:gap-y-24 gap-x-32 xl:(flex )`;
const Left = tw.div`w-full xl:w-1/2 flex flex-col relative md:pt-10 xl:pt-28`;
const Right = tw.div`w-full xl:w-1/2 grid grid-cols-1 items-center xl:items-stretch relative gap-20`;

const FrontContent3 = ({ ...rest }) => {
  const { contentA, contentB, images, link } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFrontContent3 {
          frontContent3 {
            contentA: fc3ContentA
            contentB: fc3ContentB
            images: fc3Images {
              background {
                ...GatsbyImage_Blur
              }
              foreground {
                ...GatsbyImage_Blur
              }
            }
            link {
              ...Button
            }
          }
        }
      }
    }
  `).wp.acfOptionsFrontContent3.frontContent3;
  return (
    <PageWrapper
      {...rest}
      tw="overflow-visible px-6 w-full relative md:px-12 xl:(px-40 pt-20) mt-0 grid grid-cols-1 gap-20"
    >
      <Top>
        <Left>
          <Content>{contentA}</Content>
        </Left>
        <Right>
          <Parallax
            background={images.background}
            foreground={images.foreground}
          />
          <Content>{contentB}</Content>
        </Right>
      </Top>
      <Button {...link} tw="self-center mx-auto" />
    </PageWrapper>
  );
};

export default FrontContent3;
