import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link as Glink } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

import HeroAccent from "./HeroAccent";

const Hero = styled.section`
  ${tw`bg-dark relative flex flex-col items-center justify-center w-full min-h-screen gap-6`}

  .gatsby-image-wrapper {
    ${tw`absolute top-0 left-0 w-full h-full`}

    img {
      ${tw`object-top`}
    }
  }
`;

const TitleBlock = tw.div`text-primary flex leading-none! flex-col items-center gap-1 font-mazuis italic text-2xl sm:text-4xl md:text-5xl tracking-widest`;
const Video = styled.video(({ videoLoading }) => [
  tw` absolute top-0 left-0 object-cover w-full h-full brightness-[30%] duration-700 ease-in-out blur-0 opacity-100`,
  !!videoLoading && tw`blur-md opacity-0`,
]);
const TitleLine = styled.div(({ line }) => [
  tw`[strong]:(text-white text-5xl sm:text-7xl md:text-8xl uppercase font-normal) opacity-0 duration-[1.25s] blur-sm`,
  line === 0 && tw`[p]:(flex items-start) translate-x-[50px]`,
  line === 1 && tw`self-start ml-12 translate-x-[-50px]`,
  line === 2 && tw`self-end mr-16 translate-x-[50px]`,

  css`
    &.active {
      ${tw`blur-0 translate-x-0 opacity-100`}
    }
  `,
]);

const HeroLinkStyles = () => [
  tw`text-primary font-mazuis flex italic text-2xl tracking-widest relative text-center mx-8 pb-3 duration-500 ease-in-out after:(content[''] bg-primary h-px w-full bottom-0 left-0 absolute duration-500 ease-in-out) [&:first-child]:(hidden sm:flex)`,
  css`
    &:hover {
      ${tw`after:bg-white text-white`}
    }
  `,
];

const Buttons = tw.div`absolute bottom-0 flex flex-col sm:flex-row w-full items-center mb-8 justify-center`;

const IntLink = styled(Glink)(HeroLinkStyles);
const SmoothLink = styled(AnchorLink)(HeroLinkStyles);

const FrontHero = () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFrontHero {
          frontHero {
            buttons: fhButtons {
              button {
                ...Button
              }
            }
            videoSrc: fhVideoSource
            title: fhTitleBlock {
              line
            }
            background: fhBackground {
              ...GatsbyImage_Blur
            }
            mobileBackground: fhMobileBackground {
              ...GatsbyImage_Blur
            }
          }
        }
      }
    }
  `);

  const { title, videoSrc, buttons, mobileBackground } =
    wp.acfOptionsFrontHero.frontHero;
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const stagger = 400;
    const titleLines = document.querySelectorAll(".fh-title-line");
    titleLines.forEach((line, index) => {
      setTimeout(() => {
        line.classList.add("active");
      }, index * stagger);
    });
  }, []);

  return (
    <Hero>
      {/* {typeof window !== "undefined" && window.innerWidth < 756 && (
        <GatsbyImage
          image={getImage(mobileBackground?.localFile)}
          alt="Hero Background"
        />
      )} */}
      {typeof window !== "undefined" && videoSrc && (
        <Video
          onLoadedData={() => {
            setVideoLoading(false);
          }}
          videoLoading={videoLoading}
          autoPlay
          loop
          muted
          playsInline
          id="vid"
          width="100%"
          height="100%"
        >
          <source src={videoSrc} type="video/mp4" />
        </Video>
      )}
      <HeroAccent tw="scale-y-[-1]" />
      <TitleBlock>
        {title.map(({ line }, i) => {
          return (
            <TitleLine
              className="fh-title-line"
              key={i}
              line={i}
              dangerouslySetInnerHTML={{ __html: line }}
            />
          );
        })}
      </TitleBlock>
      <HeroAccent />
      <Buttons>
        {buttons.map(({ button }, i) => {
          let Link = IntLink;
          let props = {
            to: button.url,
          };

          if (button?.url?.slice(0, 1) === "#") {
            Link = SmoothLink;
            props = {
              href: button.url,
              offset: "150px",
            };
          }
          return (
            <Link key={i} {...props}>
              {button.title}
            </Link>
          );
        })}
      </Buttons>
    </Hero>
  );
};

export default FrontHero;
