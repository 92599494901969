import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import FrontHero from "../components/FrontHero";
import FrontReviews from "../components/FrontReviews";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import FrontContent1 from "../components/FrontContent1";
import FrontContent2 from "../components/FrontContent2";
import FrontContent3 from "../components/FrontContent3";
import Awards from "../components/Awards";

const IndexPage = ({ data }) => {
  if (!data.wpPage) {
    throw new Error(
      "Home page query came back empty. Do you have a home page set in WordPress?"
    );
  }
  return (
    <Layout
      seo={data.wpPage.seo}
      schemaCodes={data.wpPage.pageSettings.schemaCodes}
    >
      <FrontHero />
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <FrontContent1 />
      <FrontContent2 />
      <FrontContent3 />
      <Awards />
    </Layout>
  );
};

export const pageQuery = graphql`
  # The page query for the front page's information.
  query {
    wpPage(isFrontPage: { eq: true }) {
      ...SEO
      pageSettings {
        schemaCodes {
          singleSchema
        }
      }
    }
  }
  # Fragment for Yoast's SEO data on pages
  fragment SEO on WpPage {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }

  # Fragment for Yoast's SEO data on posts
  fragment PostSEO on WpPost {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }

  # Fragment for link buttons
  fragment Button on WpAcfLink {
    url
    target
    title
  }

  # Fragment for Gatsby images from media library
  fragment GatsbyImage_Blur on WpMediaItem {
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    altText
  }
  fragment GatsbyImage_SVG on WpMediaItem {
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, quality: 90)
      }
    }
    altText
  }
`;

export default IndexPage;
